@tailwind base;
@tailwind components;
@tailwind utilities;


/* Style for first level ordered list */
ol.terms-level1 {
    list-style-type: decimal;
    margin: 1.2em;
}

ol.terms-level1 > li {
    counter-increment: item;
    padding: 0.2em;
    font-weight: bold;
}

/* Style for second level ordered list */
ol.terms-level2 {
    list-style-type: none;
    counter-reset: item;
    padding-left: 2em;
}

ol.terms-level2 > li {
    counter-increment: item;
    padding: 0.2em;
    font-weight: normal;

    align-items: flex-start;
    display: flex;
}


ol.terms-level2 > li:before {
    margin-right: 1em;
    display: inline-block;
    content: counters(item, ".") ". ";
    flex-shrink: 0;
}

/* Style for third level ordered list */
ol.terms-level3 {
    list-style-type: lower-alpha;
    padding-left: 4em;
}

ol.terms-level3 > li {
    padding: 0.2em;
    font-weight: normal;
}
